import { useState, useEffect } from 'react';
import {
  AdvancedWriteRule,
  ReadFields,
  UserRules,
  WriteFields,
  WriteRule,
  WriteRuleType,
} from '@mymeeinc/types/graphql/rules/types';
import { UserTypes } from '@mymeeinc/types/user';
import { isFunction } from '@mui/x-data-grid/internals';
import { ContentTypes } from '@mymeeinc/types/content';
import { TrackingTypes } from '@mymeeinc/types/tracking';

export function useTimeout(fn: any, ms: number) {
  useEffect(() => {
    const timeout = setTimeout(() => {
      fn();
    }, ms);
    return () => clearTimeout(timeout);
  }, [fn, ms]);
}

export function useShowLoader(loadingMapState: { [key: string]: boolean }, delayMs: number) {
  const [showLoader, setShowLoader] = useState(false);
  useTimeout(() => {
    if (Object.entries(loadingMapState).length === 0) {
      setShowLoader(false);
    }
  }, delayMs);
  useEffect(() => {
    if (Object.entries(loadingMapState).length > 0) {
      setShowLoader(true);
    }
  }, [loadingMapState]);
  return showLoader;
}

export const getReadRule = (
  userRules: UserRules,
  actor: UserTypes,
  object: UserTypes | ContentTypes | TrackingTypes
): ReadFields => {
  const value = userRules[actor].reads[object];

  if (isFunction(value)) {
    return value();
  }
  return value;
};
export const getWriteRule = (
  userRules: UserRules,
  actor: UserTypes,
  object: UserTypes | ContentTypes | TrackingTypes,
  writeRuleType: WriteRuleType = WriteRuleType.Insert
): { inputs: WriteFields; fields: WriteFields } => {
  if (!userRules[actor].writes[object]) {
    console.warn('Unknown write rule:');
    console.log({ actor, object, userRules });
    console.log({ obj: userRules[actor].writes[object] });
  }
  const writeRule = userRules[actor].writes[object];
  if (Object.hasOwn(writeRule, 'inputs') && Object.hasOwn(writeRule, 'fields')) {
    const { inputs, fields } = writeRule as WriteRule;
    return {
      inputs: isFunction(inputs) ? inputs() : inputs,
      fields: isFunction(fields) ? fields() : fields,
    };
  }
  if (Object.hasOwn(writeRule, 'insert') && Object.hasOwn(writeRule, 'update')) {
    const { inputs, fields } = (writeRule as AdvancedWriteRule)[writeRuleType];

    return {
      inputs: isFunction(inputs) ? inputs() : inputs,
      fields: isFunction(fields) ? fields() : fields,
    };
  }

  throw new Error('unknown write structure');
};
